export default {
    methods: {
        async loadData(clientId, callback) {
            var rs = await this.$store.dispatch('getServiceCardList', {
                clientId: clientId,
            })
            var list = []
            if(rs && rs['clientServiceCards']) {
                rs['clientServiceCards'].forEach((item)=>{
                    var filterData = list.find(d=>d.name == item.companyshortname)
                    if(!filterData) {
                        filterData = {
                            name: item.companyshortname,
                            values: []
                        }
                        list.push(filterData)
                    }
                    filterData.values.push(item)
                })
                callback && callback({
                    list: list
                }, null)
            } else {
                callback && callback({
                    list: list
                }, rs)
            }
        },
        async getDetail(clientId, clientservicecardid, callback) {
            var rs = await this.$store.dispatch('getServiceCardDetail', {
                clientId: clientId,
                clientservicecardid: clientservicecardid
            })
            var error = (rs == null || rs.total == null) ? rs : null
            var list = rs.list || []
            list.forEach((d)=>{
                if(d.operationtype == 'D') {
                    d.level = 0
                    d.transactionamount = '-'+d.transactionamount.toFixed(2)
                } else if(d.operationtype == 'R') {
                    d.level = 1
                    d.transactionamount = '+'+d.transactionamount.toFixed(2)
                } else if(d.operationtype == 'S') {
                    d.transactionamount = '+'+d.transactionamount.toFixed(2)
                    d.level = 2
                }
            })
            callback && callback({
                list: list,
                total: rs.total,
            }, error)
        }
    }
}