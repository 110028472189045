<template>
	<div id="plan-card-detail">
		<div class="detail-loading" v-if="loading">
			<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			<span class="text">正在加载...</span>
		</div>
		<div class="statement">
			<div class="statement-head">
				<span class="hint">充值卡消費明細</span>
				<span class="btn-all">查看全部</span>
			</div>
			<div :class="{'statement-item': true, ['level'+item.level]: true}" v-for="(item, index) in dataList" :key="index">
				<div class="row">
					<span class="s-clinic-name" v-text="item.clinicname"></span>
					<span class="s-free" v-text="item.transactionamount"></span>
				</div>
				<div class="row">
					<span class="s-doctor-name" v-text="item.clientname">方唐镜</span>
					<span class="s-time" v-text="item.createdtime"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import service_card from '@/views/mixins/service_card'
	export default {
		props: {
			id: null,
		},
		mixins: [
			service_card
		],
		components: {
		},
		data(){
			return {
				loading: true,
				dataList: [],
			}
		},
		mounted: function(){
			this.loading = true
			this.getDetail(this.$store.getters.clientId, this.id, (data, error)=>{
				if(error) {
					this.loading = false
					this._toast.warning(this, error)
					return
				}
				this.loading = false
				this.dataList = data.list
			})
		},
	}
</script>

<style lang="scss" scoped>
	.statement {
		display: flex;
		flex-direction: column;
		padding: 0px 16px;
	}
	
	.statement .statement-head {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 110px;
		line-height: 110px;
		color: #999999;
		font-size: 28px;
		font-weight: 400;
		align-items: center;


		display: none;
	}

	.statement .statement-head > .btn-all {
		height: 48px;
		line-height: 48px;
		padding: 0px 14px 0px 13px;
		background-color: #fff;
		border-radius: 10px;
		border: 1px solid #333333;
		color: #333333;
		font-size: 28px;
		font-weight: 400;
	}

	.statement .statement-head > .btn-all:active {
		opacity: 7;
	}

	.statement .statement-item {
		display: flex;
		flex-direction: column;
		margin: 24px 0px;
		position: relative;
	}

	.statement .statement-item .row {
		display: flex;
		flex-direction: row;
	}

	.statement .statement-item .row:nth-child(1) {
		justify-content: space-between;
	}

	.statement .statement-item .s-clinic-name {
		color: #1F1F1F;
		font-size: 32px;
		font-weight: bold;
		position: relative;
		padding-left: 46px;
		line-height: 31px;
	}

	.statement .statement-item .s-clinic-name::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 30px;
		height: 30px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.statement .statement-item.level0 .s-clinic-name::before {
		background-image: url(../../../../assets/imgs/mobile/user_center/icon_tui@2x.png);
	}

	.statement .statement-item.level1 .s-clinic-name::before {
		background-image: url(../../../../assets/imgs/mobile/user_center/icon_zhen@2x.png);
	}

	.statement .statement-item.level2 .s-clinic-name::before {
		background-image: url(../../../../assets/imgs/mobile/user_center/icon_chong@2x.png);
	}

	.statement .statement-item .s-free {
		font-size: 36px;
		font-weight: bold;
		color: #FF3F12;
	}

	.statement .statement-item.level1 .s-free {
		color: #1F1F1F;
	}

	.statement .statement-item.level2 .s-free {
		color: #FF9E06;
	}

	.statement .statement-item .s-time,
	.statement .statement-item .s-doctor-name {
		color: #999999;
		font-size: 24px;
		line-height: 23px;
		font-weight: 400;
	}

	.statement .statement-item .s-time {
		margin: 15px 0px 0px 46px;
	}

	.statement .statement-item .s-doctor-name {
		margin: 15px 0px 0px 45px;
	}

	.statement .statement-item .s-get {
		color: #1F1F1F;
		font-weight: 400;
		font-size: 24px;
		line-height: 23px;
		position: absolute;
		right: 0;
		bottom: 0;
	}

	// detail-loading start
	.detail-loading {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding-top: 40px;
		box-sizing: border-box;
	}

	.detail-loading > .text {
		margin-left: 24px;
		font-size: 32px;
		font-weight: 400;
		color: #999999;
	}

	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
	//detail-loading end
</style>
