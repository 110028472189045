<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="card-list" style="min-height: 100vh;" ref="listView">
			<div class="card-item" v-for="(plan, index) in dataList" :key="index">
				<div class="card-head">
					<span class="clinic-name" v-text="plan.name"></span>
					<span class="card-type">服務卡</span>
				</div>
				<div class="card-detail-w" v-for="(detail, i) in plan.values" :key="i" @click="showDetail">
					<div class="card-detail">
						<div class="row">
							<span class="card-code">卡号：{{detail.servicecardnumber}}</span>
							<span class="free">{{detail.servicecardamount.toFixed(2)}}</span>
						</div>
						<div class="row">
							<span class="card-time">失效日期：{{detail.servicecardexpiredate}}</span>
							<span class="free-hint">结余</span>
						</div>
					</div>
					<service-card-detail v-if="detailVisible" :id="detail.clientservicecardid"/>
				</div>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
		<loading v-if="loading"/>
	</van-pull-refresh>
</template>

<script>
	import service_card from '@/views/mixins/service_card'
	import Loading from '@/layouts/Loading.vue'
	import ServiceCardDetail from './ServiceCardDetail'
	export default {
		mixins: [
			service_card
		],
		components: {
			Loading,
			ServiceCardDetail
		},
		data(){
			return {
				loading: true,
				dataList: [],
				detailList: [],
				isRefresh: false,
				isLoadFinished: false,
				detailVisible: false,
				detailId: null,
			}
		},
		mounted: function(){
			this.loading = true
			this.onRefresh()
		},
		methods: {
			showDetail: function(){
				this.detailVisible = !this.detailVisible
			},
			onRefresh: function(){
				this.isLoadFinished = false
				this.loadData(this.$store.getters.clientId, (data, error)=>{
					if(error) {
						this.loading = false
						this._toast.warning(this, error)
						return
					}
					this.loading = false
					this.isRefresh = false
					this.dataList = data.list
					this.isLoadFinished = true
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.card-list {
		display: flex;
		flex-direction: column;
		padding-top: 16px;
	}

	.card-list > .card-item {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding-bottom: 20px;
		padding: 26px 24px;
	}

	.card-list > .card-item .card-head {
		display: flex;
		flex-direction: row;
		height: 40px;
		align-items: center;
		justify-content: space-between;
	}

	.card-list > .card-item > .card-head > .clinic-name {
		color: #1F1F1F;
		font-size: 34px;
		line-height: 33px;
		padding-left: 48px;
		position: relative;
	}

	.card-list > .card-item > .card-head > .clinic-name::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 30px;
		height: 28px;
		background-image: url(../../../../assets/imgs/mobile/icon_zhensuo@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.card-list > .card-item > .card-head > .card-type {
		height: 40px;
		line-height: 40px;
		padding-left: 20px;
		padding-right: 19px;
		color: #fff;
		background-color: #FFAE00;
		border-radius: 20px;
		font-size: 24px;
		font-weight: 400;
	}

	.card-list > .card-item .card-detail {
		height: 170px;
		border-radius: 16px;
		box-sizing: border-box;
		padding: 40px 48px 49px 33px;
		margin-top: 44px;
		display: flex;
		flex-direction: column;
		background:linear-gradient(0deg,rgba(255,174,0,1) 0%,rgba(255,223,111,1) 100%);
	}

	.card-list > .card-item .card-detail .row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 28px;
		color: #fff;
	}

	.card-list > .card-item .card-detail .row:nth-child(2n) {
		margin-top: 24px;
	}

	.card-list > .card-item .card-detail .card-code {
		font-size: 34px;
		color: #fff;
		line-height: 32px;
	}

	.card-list > .card-item .card-detail .free {
		font-size: 36px;
		color: #fff;
		line-height: 2px;
	}

	.statement {
		padding: 0 16px;
	}

	.statement .statement-head {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 110px;
		line-height: 110px;
		color: #999999;
		font-size: 28px;
		font-weight: 400;
		align-items: center;
	}

	.statement .statement-head > .btn-all {
		height: 48px;
		line-height: 48px;
		padding: 0px 14px 0px 13px;
		background-color: #fff;
		border-radius: 10px;
		border: 1px solid #333333;
		color: #333333;
		font-size: 28px;
		font-weight: 400;
	}

	.statement .statement-head > .btn-all:active {
		opacity: 7;
	}

	.statement .statement-item {
		display: flex;
		flex-direction: column;
		margin-bottom: 48px;
		position: relative;
	}

	.statement .statement-item .row {
		display: flex;
		flex-direction: row;
	}

	.statement .statement-item .row:nth-child(1) {
		justify-content: space-between;
	}

	.statement .statement-item .s-clinic-name {
		color: #1F1F1F;
		font-size: 32px;
		font-weight: bold;
		position: relative;
		padding-left: 46px;
		line-height: 31px;
	}

	.statement .statement-item .s-clinic-name::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 30px;
		height: 30px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.statement .statement-item.level0 .s-clinic-name::before {
		background-image: url(../../../../assets/imgs/mobile/user_center/icon_tui@2x.png);
	}

	.statement .statement-item.level1 .s-clinic-name::before {
		background-image: url(../../../../assets/imgs/mobile/user_center/icon_zhen@2x.png);
	}

	.statement .statement-item.level2 .s-clinic-name::before {
		background-image: url(../../../../assets/imgs/mobile/user_center/icon_chong@2x.png);
	}

	.statement .statement-item .s-free {
		font-size: 36px;
		font-weight: bold;
		color: #FF3F12;
	}

	.statement .statement-item.level1 .s-free {
		color: #1F1F1F;
	}

	.statement .statement-item.level2 .s-free {
		color: #FF9E06;
	}

	.statement .statement-item .s-time,
	.statement .statement-item .s-doctor-name {
		color: #999999;
		font-size: 24px;
		line-height: 23px;
		font-weight: 400;
	}

	.statement .statement-item .s-time {
		margin: 15px 0px 0px 46px;
	}

	.statement .statement-item .s-doctor-name {
		margin: 15px 0px 0px 45px;
	}

	.statement .statement-item .s-get {
		color: #1F1F1F;
		font-weight: 400;
		font-size: 24px;
		line-height: 23px;
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.statement .notice {
		display: block;
		width: 217px;
		height: 27px;
		line-height: 27px;
		color: #999999;
		font-size: 28px;
		font-weight: 400;
		position: relative;
		margin: 0px auto;
	}

	.statement .notice::before,
	.statement .notice::after {
		content: '';
		position: absolute;
		left: -100%;
		top: 50%;
		transform: translateY(-50%);
		width: 150px;
		height: 1px;
		background-color: #999999;
	}

	.statement .notice::before {
		left: 100%;
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
		margin-top: 40px;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}
</style>
